/* Stats Talents Panel */
#stats-talents {
  cursor: pointer;
}

#stats-talents-title:hover {
  color: var(--bg-color);
}

.stats-talents-main {
  margin-bottom: 5px;
}
