/* Modals */
.modal-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.modal-body {
  padding: 10px;
}

#modal-app-icon {
  float: right;
  height: 70px;
}

.modal-reminder {
  font-size: 0.8em;
}

.tab-title {
  font-size: 0.8em;
}

.tab-content {
  margin-top: 5px;
  padding: 5px;
}

.about-label {
  font-weight: bold;
}

#coffee {
  height: 25px;
  border-radius: 5px;
}

.button-releases {
  margin: 0 auto;
  display: block;
}

.button-releases-history {
  margin-left: 2px;
  margin-bottom: 2px;
  display: block;
}

#button-tour {
  margin: 0 auto;
  display: block;
}

.share-modal-label {
  font-weight: 500;
  margin-bottom: 5px;
}

#share-modal-social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; /* horizontal */
  align-items: center; /* vertical */
  cursor: pointer;
}
