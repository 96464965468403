/* Info panel */
#info-panel {
  height: 100%;
  width: 100%;
  padding: 4px;
  overflow-y: auto;
  overflow-wrap: break-word;
  z-index: 10;
  background-image: linear-gradient(
    to left,
    var(--bg-color),
    var(--bg-color-dark)
  );
}

.info-panel-open {
  flex: 0 0 var(--info-panel-size);
}

.info-panel-closed {
  display: none;
}

.info-box {
  background-color: white;
  border: 0.5px solid black;
  border-radius: 5px;
  padding: 6px;
  margin-bottom: 5px;
}
