/* Summary Panel */
#summary-panel-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; /* horizontal */
}

#summary-panel {
  position: relative;
  overflow: hidden;
  flex-grow: 2;
}

#summary-panel h1 {
  margin-bottom: 0px;
}

#summary-panel h3 {
  margin-bottom: 6px;
}

#summary-panel hr {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}

#summary-panel-guides {
  font-style: normal;
}

.summary-panel-guide-icon {
  height: 0.9em;
  width: auto;
  margin-left: 0.15em;
  margin-right: 0.15em;
  vertical-align: baseline;
}

#summary-panel-summary p {
  margin-bottom: 0px;
}
