/* Base font styles (default: desktop large) */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styles */
h1 {
  font-weight: bold;
}

h2 {
  font-weight: bold;
}

h3 {
  font-style: italic;
}

.stats-talents-title {
  font-weight: bold;
}

.node-tooltip-title {
  font-weight: bold;
}

@media (max-width: 820px) {
  .nav-button-text {
    display: none;
  }
}

/* Phone */
@media (max-width: 599px) and (orientation: portrait) {
  h1 {
    font-size: 0.8em;
  }

  h2 {
    font-size: 0.7em;
  }

  h3 {
    font-size: 0.5em;
  }

  p {
    font-size: 0.5em;
  }

  #banner {
    font-size: 0.55em;
  }

  #banner img {
    height: 1.1em;
  }

  .popover {
    border-radius: 5px !important;
  }

  .popover-header {
    padding: 4px;
  }

  .popover-body {
    padding: 2px !important;
  }

  .node-tooltip-title {
    font-size: 0.6em;
  }

  .node-tooltip-id {
    font-size: 0.3em;
  }

  .node-tooltip-bg {
    font-size: 0.5em;
    border-radius: 2px !important;
  }

  .node-tooltip-decrease {
    font-size: 0.8em;
  }

  .node-tooltip-value {
    font-size: 0.8em;
  }

  .node-tooltip-increase {
    font-size: 0.8em;
  }
}

@media (max-height: 599px) and (orientation: landscape) {
  h1 {
    font-size: 0.7em;
  }

  h2 {
    font-size: 0.6em;
  }

  h3 {
    font-size: 0.6em;
  }

  p {
    font-size: 0.6em;
  }

  #banner {
    font-size: 0.55em;
  }

  #banner img {
    height: 1.1em;
  }

  .bullet {
    width: 5px;
    height: 5px;
    margin-top: 4px;
    margin-right: 3px;
  }

  .stats-talents-title {
    font-size: 0.5em;
  }

  .stats-talents-text {
    font-size: 0.45em;
    padding-left: 2px;
  }

  .popover {
    border-radius: 3px !important;
  }

  .popover-header {
    padding: 2px;
  }

  .popover-body {
    padding: 2px !important;
  }

  .node-tooltip-title {
    font-size: 8px;
  }

  .node-tooltip-id {
    font-size: 4px;
  }

  .node-tooltip-bg {
    font-size: 6px;
    border-radius: 2px !important;
  }

  .node-tooltip-decrease {
    font-size: 0.6em;
  }

  .node-tooltip-value {
    font-size: 0.6em;
  }

  .node-tooltip-increase {
    font-size: 0.6em;
  }
}

/* Tablet */
@media (min-width: 600px) and (orientation: portrait) {
  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 1.2em;
  }

  #banner {
    font-size: 0.9em;
  }

  #banner img {
    height: 1.1em;
  }

  .node-tooltip-title {
    font-size: 1.2em;
  }

  .node-tooltip-id {
    font-size: 0.6em;
  }

  .node-tooltip-bg {
    font-size: 1.1em;
  }

  .node-tooltip-decrease {
    font-size: 1.6em;
  }

  .node-tooltip-value {
    font-size: 1.6em;
  }

  .node-tooltip-increase {
    font-size: 1.6em;
  }
}

@media (min-width: 900px) and (orientation: landscape) {
  h1 {
    font-size: 1.2em;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 0.8em;
  }

  p {
    font-size: 0.8em;
  }

  #banner {
    font-size: 0.9em;
  }

  #banner img {
    height: 1.1em;
  }

  .bullet {
    width: 7px;
    height: 7px;
    margin-top: 5px;
    margin-right: 4px;
  }

  .stats-talents-title {
    font-size: 0.7em;
  }

  .stats-talents-text {
    font-size: 0.6em;
    padding-left: 4px;
  }

  .node-tooltip-title {
    font-size: 0.9em;
  }

  .node-tooltip-id {
    font-size: 0.4em;
  }

  .node-tooltip-bg {
    font-size: 0.8em;
  }

  .node-tooltip-decrease {
    font-size: 1.2em;
  }

  .node-tooltip-value {
    font-size: 1.2em;
  }

  .node-tooltip-increase {
    font-size: 1.2em;
  }
}

/* Desktop */
@media (min-height: 1280px) and (orientation: portrait) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.5em;
  }

  #banner {
    font-size: 1em;
  }

  #banner img {
    height: 1.2em;
  }

  .node-tooltip-title {
    font-size: 1.4em;
  }

  .node-tooltip-id {
    font-size: 0.8em;
  }

  .node-tooltip-bg {
    font-size: 1.4em;
  }

  .node-tooltip-decrease {
    font-size: 2em;
  }

  .node-tooltip-value {
    font-size: 2em;
  }

  .node-tooltip-increase {
    font-size: 2em;
  }
}

@media (min-width: 1280px) and (orientation: landscape) {
  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }

  #banner {
    font-size: 1em;
  }

  #banner img {
    height: 1.2em;
  }

  .bullet {
    width: 10px;
    height: 10px;
    margin-top: 6px;
    margin-right: 5px;
  }

  .stats-talents-title {
    font-size: 0.9em;
  }

  .stats-talents-text {
    font-size: 0.8em;
    padding-left: 5px;
  }

  .node-tooltip-title {
    font-size: 1em;
  }

  .node-tooltip-id {
    font-size: 0.6em;
  }

  .node-tooltip-bg {
    font-size: 1em;
  }

  .node-tooltip-decrease {
    font-size: 1.6em;
  }

  .node-tooltip-value {
    font-size: 1.6em;
  }

  .node-tooltip-increase {
    font-size: 1.6em;
  }
}
