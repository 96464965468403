/* Nav bar */
.navbar {
  height: var(--nav-bar-height);
  width: 100vw;
  border-bottom: 1px solid black;
  background-color: #fff;
  z-index: 20;
}

.nav-speed {
  background-color: var(--bg-color-speed);
}

#nav-app-icon {
  height: 25px;
}

#nav-icon {
  color: black;
}

#nav-icon:hover {
  color: var(--bg-color);
}

.nav-menu {
  max-height: 80vh;
  overflow: auto;
}
