.help-wrapper {
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.help-icon {
  font-size: 12px;
}

.help-text {
  font-size: 0.8em;
}
