#select-commander-menu {
  margin-top: 0;
  padding-top: 0;
}

.select-commander-icon-header {
  margin-right: 0px !important;
  width: 24px !important;
}

.select-commander-icon {
  margin-right: 6px;
  width: 30px;
  height: auto;
  transform: translateY(-10%);
}

.dropdown-item {
  padding-left: 20px;
  padding-bottom: 2px;
  padding-top: 0px;
}
