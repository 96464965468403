/* Global variables */
:root {
  --nav-bar-height: 40px;
  --info-panel-size: 20%;
  --hexagon-opacity: 1;
  --tree-box-outline: none;
  --bg-color: rgb(14, 50, 71);
  --bg-color-dark: rgb(0, 40, 66);
  --bg-color-light: rgb(16, 75, 109);
  --bg-color-speed: rgb(225, 237, 243);
  --color-highlight: rgb(236, 95, 0);
  --red: rgba(190, 50, 25, 1);
  --yellow: rgba(183, 156, 4, 1);
  --blue: rgba(22, 159, 227, 1);
  --inactive-opacity: 0.2;
  --line-width-active: 1;
}

.bg-red {
  background-color: var(--red);
}

.bg-yellow {
  background-color: var(--yellow);
}

.bg-blue {
  background-color: var(--blue);
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overscroll-behavior: contain;
  margin: 0;
}

/* Prevents auto hiding URL bar on mobile. Useful if panzoom is enabled */
/* body {
  position: fixed;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
} */

div {
  outline-style: none;
}

#app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start; /* horizontal */
  align-items: center; /* vertical */
}

#app ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#app ::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#app ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(112, 153, 177);
}

#spinner {
  text-align: center;
  color: white;
}

.bullet {
  float: left;
  border: 1px solid rgba(0, 0, 0, 1);
}

/* Main panel container */
#main-container {
  width: 100vw;
  height: calc(100% - var(--nav-bar-height));
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: center; /* horizontal */
  align-items: center; /* vertical */
  background-color: var(--bg-color);
}

@media (orientation: portrait) {
  #main-container {
    flex-flow: column nowrap;
  }

  #info-panel {
    background-image: linear-gradient(
      to top,
      var(--bg-color),
      var(--bg-color-dark)
    );
  }

  #summary-panel-container {
    height: 100%;
  }

  #summary-panel {
    height: 100%;
  }

  #stats-panel {
    height: 100%;
    margin-left: 3px;
  }

  .info-box {
    margin-bottom: 0px;
  }
}
