.navbar .btn {
  margin: 1px;
  margin-right: 2px;
}

.navbar .btn.btn-sm:disabled {
  -webkit-filter: saturate(10%);
  filter: saturate(10%);
}

.nav-button-text {
  margin-left: 5px;
}